/*!

=========================================================
* BLK Design System PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
//import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Button,
  //Badge,
  Container,
  Row,
  Col,
  Card,
  //CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
} from "reactstrap";
import ContactUs from "./SectionsSections/ContactUs2.js";
//import OurPartners from "./SectionsSections/OurPartners.js";
import SlickCarousel from "./SectionsSections/slickTest.js";
// import peelsBusinessLogo from '../assets/img/peels-business.png';

export default function Index() {

  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
  return (

    <>
      <ColorNavbar scrollColor="bg-primary" />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header">
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png")}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png")}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png")}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png")}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png")}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png")}
          />
          <Container id="logosection">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-dark">
                  PEELS: Revolutionizing Licensing in the Phygital Realm - <br />
                </h1>  <h3 className="text-dark"> Facilitating Global Market Reach and
                  Strategic IP Transition from Web 2 to Web 3.</h3>

                <p className="text-dark mb-3">
                  The world's first blockchain-based phygital marketplace, engineered to solve the fundamental challenges
                  facing both brands and consumers. Our platform enables brands to authenticate and introduce mainstream
                  products into the unique environment of the phygital world.
                </p>
                {/* <div className="btn-wrapper mb-3">
                  <p className="category text-success d-inline">
                    From 9.99%/mo
                  </p>
                  <Button
                    className="btn-link"
                    color="success"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    <i className="tim-icons icon-minimal-right" />
                  </Button>
                </div> */}
                <div className="btn-wrapper">
                  <div className="button-container">
                    <Button
                      className="btn-icon btn-simple btn-round mr-1"
                      color="reddit"
                      href="https://x.com/PEELS_io"
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    {/* <Button
                      className="btn-icon btn-simple btn-round mr-1"
                      color="reddit"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                    <Button
                      className="btn-icon btn-simple btn-round"
                      color="reddit"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook" />
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col lg="4" md="5" sm="6" className="p-3">
                <img alt="..." className="img-fluid" src={require("assets/img/logo.png")} />
                {/* <img alt="..." className="img-fluid" src={require("assets/img/peels-logo2.png")} /> */}
              </Col>
            </Row>
          </Container>
        </div>


        <div className="section section-cards" id="cards">
          {/* ********* BLOG CARDS ********* */}
          <div className="cards">
            <Container>
              {/* <div className="title">
              <h3 className="title">Cards</h3>
              <h3>
                <small>Blog Cards</small>
              </h3>
            </div> */}
              <Row>
                <Col lg="4" md="6">
                  <Card className="bg-dark">
                    <CardBody>
                      {/* <h6 className="category text-success">
                      <i className="tim-icons icon-sound-wave mr-2" />
                      <i className="tim-icons icon-tap-02 mr-2"></i>
                      
                    </h6> */}

                      <div className="info info-hover">
                        <div className="icon icon-primary">
                          <img
                            alt="..."
                            className="bg-blob"
                            src={require("assets/img/feature-blob/primary.png")}
                          />
                          <i className="tim-icons icon-user-run" />
                        </div>
                        <h4 className="info-title">What problem are we addressing?</h4>
                        <p className="card-description text-left">
                          With a growing number of mainstream brands producing both digital and physical products seeking to enter the Web 3 market, a primary barrier emerges for licensing companies: the management of recurring revenues, their royalties and the protection of their Intellectual Property.
                        </p>

                        <p className="card-description text-left">
                          There's currently no centralised and secure platform built to handle the complexities of Phygital trading. Traditional large-scale web 2 marketplaces typically necessitate extensive warehousing facilities, staff to handle verification and logistics. In contrast, our marketplace streamlines these processes through direct peer to peer (p2p) interactions.
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" md="6">
                  <Card className="bg-dark">
                    <CardBody>
                      <div className="info info-hover">
                        <div className="icon icon-success">
                          <img
                            alt="..."
                            className="bg-blob"
                            src={require("assets/img/feature-blob/success.png")}
                          />
                          <i className="tim-icons icon-tap-02" />
                        </div>
                        <h4 className="info-title">Our Solution</h4>
                        <p className="card-description text-left">
                          Utilising blockchain technology, we aim to address these challenges by implementing a built-in segregation function for all licensed products and partners. Our scalable solutions enable companies and users to trade their phygital products, incorporating transferability and embedded NFC authentication to eliminate the need for intermediaries in verifying product authenticity. This function ensures that royalties are directly funnelled to the license holder, regardless of the number of times an NFT or Phygital is bought or sold.</p>
                        <p className="card-description text-left">
                          This streamlined process alleviates numerous concerns for brands considering entry into Web 3. Our goal is to cut out the red tape when it comes to IPs, licensing,
                          distribution, copyrights and to simplify them as much as possible, creating a transparent and welcoming environment for brands and IPs to enter into the Web 3 realms.
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" md="8">
                  <Card className="bg-dark">
                    <CardBody>
                      <div className="info info-hover">
                        <div className="icon icon-warning">
                          <img
                            alt="..."
                            className="bg-blob"
                            src={require("assets/img/feature-blob/warning.png")}
                          />
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <h4 className="info-title">PEELZ Marketplace</h4>
                        <p className="card-description text-left">
                          The PEELZ marketplace is not only a platform for buying and selling but also serves as a hub for artists and creative services, helping them to connect, collaborate and gain exposure within the Web 3 industry. This provides valuable opportunities for artists to enhance their projects with added utility and broaden their social reach with a greater network effect.Furthermore, the PEELZ marketplace introduces a rewarding system for users and token holders, featuring revenue sharing and royalty sharing mechanisms. This incentivizes active participation and engagement within the platform.
                        </p>

                        <p className="card-description text-left">
                          We will continue to focus on the community & holders, giving them exclusive early access to limited edition products before general release.
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END BLOG CARDS ********* */}

        </div>


        <div className="cd-section mb-5 mt-5" id="marketplaces">

          {/* ********* Table 2 ********* */}
          <div className="tables-2">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h2 className="title mb-4 text-primary">Comparisons between Marketplaces</h2>
                  <div className="section-space" />
                </Col>
              </Row>
              <Row>
                <Col className="mx-auto" md="12">
                  <div>
                    <Card className="bg-dark">
                      <CardBody>
                        <div className="table-responsive mb-0">
                          <Table className="table-pricing">
                            <thead className="">
                              <tr style={{ borderBottomWidth: "1px ", borderBottomStyle: "solid", borderBottomColor: "#344675" }}>
                                <th className=" pb-0 py-lg-5 px-lg-5">
                                  {/* <img alt="..." className="background d-none d-md-block" src={require("assets/img/bg4.png")} />
                                  <img alt="..." className="man d-none d-md-block" src={require("assets/img/man-up.png")} />
                                  <img alt="..." className="girl d-none d-md-block" src={require("assets/img/girl-up.png")} />

                                  <img alt="..." className="background d-sm-block d-md-none" src={require("assets/img/bg4.png")} />
                                  <img alt="..." className="man d-sm-block d-md-none" src={require("assets/img/man-up.png")} />
                                  <img alt="..." className="girl d-sm-block d-md-none" src={require("assets/img/girl-up.png")} /> */}
                                </th>
                                <th className="text-center pb-0 py-lg-5 px-lg-5">
                                  <img
                                    alt="..."
                                    className="background"
                                    src={require("assets/img/peels-logo-colored.png")}
                                  />
                                </th>
                                <th className="text-center pb-0 py-lg-5 px-lg-5">
                                  <img
                                    alt="..."
                                    className="background"
                                    src={require("assets/img/ebay-logo.png")}
                                  />
                                </th>
                                <th className="text-center pb-0 py-lg-5 px-lg-5">
                                  <img
                                    alt="..."
                                    className="background"
                                    src={require("assets/img/amazon-logo-white.png")}
                                  />
                                </th>
                                <th className="text-center pb-0 py-md-3 px-md-3 py-lg-5 px-lg-5">
                                  <img
                                    alt="..."
                                    className="background"
                                    src={require("assets/img/stockx-logo2.png")}
                                  />
                                </th>

                              </tr>
                            </thead>
                            <tbody>

                              <tr>
                                <td>NFTS</td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                              </tr>
                              <tr>
                                <td>Tradability</td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                              </tr>
                              <tr>
                                <td>Direct Authentication</td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                              </tr>
                              <tr>
                                <td>Payouts on Usage</td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                              </tr>
                              <tr>
                                <td>Direct shipment to end customer</td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                              </tr>
                              <tr>
                                <td>Payment escrow</td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-check-2 text-success" />
                                </td>
                                <td>
                                  <i className="tim-icons icon-simple-remove text-danger" />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END Table 2 ********* */}
        </div>


        {/* ********* FEATURES 6 ********* */}
        <div className="features-6" id="businessofpeels">
          <Container className="d-none d-md-block">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title mb-4 text-primary">THE BUSINESS OF PEELS</h2>
                <div className="section-space" />
              </Col>
            </Row>

            <div className="align-items-center" style={{ background: `no-repeat center url('${process.env.PUBLIC_URL}/assets/img/peels-business2.png')`, backgroundSize: "50%", zIndex: '1', }} >
              <div className="row mb-5" style={{ paddingBottom: "170px" }}>
                <div className="col-6 text-middle pt-5 mt-5">
                  <div className="info info-horizontal info-custom text-left pr-5 mt-5">
                    <div className="description">
                      <span className="icon icon-warning">
                        <i className="tim-icons icon-atom" />
                      </span>
                      <h3 className="info-title text-primary">Bringing web 2 brands to web 3</h3>
                      <p className="text-dark">
                        PEELS collaborates & partners with global brands to launch products and projects via the PEELS marketplace. This streamlined approach bypasses legal complexities and enables Peer to Peer (P2P) sales. Furthermore, the marketplace offers advertising opportunities for brands and project spotlights.

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 pb-5" >
                  <div className="info info-horizontal info-custom text-right pl-5 mb-5">
                    <div className="description">
                      <span className="icon icon-info">
                        <i className="tim-icons icon-user-run" />
                      </span>
                      <h3 className="info-title text-primary">Service providers for web 2/3 companies while Utilising PEELS</h3>
                      <p className="text-dark">
                        PEELS encourages the use of its tokens and services for design, toy and merchandise production, licensing, distribution, and marketing. This initiative also supports emerging artists seeking entry into the Web 3 landscape. We also aim to be a dominant service provider for projects in web 3 to use our services for design and toy production and distribution, which will bring in significant revenues for the company as more and more projects migrate to incorporating phygitals and collectibles. Regardless of the type of activity, PEELS will be utilized every step of the way.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "85px" }}>
                <div className="col-6 pt-5">
                  <div className="info info-horizontal info-custom text-left pr-5">
                    <div className="description">
                      <span className="icon icon-info">
                        <i className="tim-icons icon-money-coins" />
                      </span>
                      <h3 className="info-title text-left text-primary">Reinvesting profits to expand license & brand portfolios Scaling</h3>
                      <p className="text-dark">
                        Utilizing our licenses and expanding network, we target diverse IPs across all collectible sectors, emphasizing universally appealing IPs suitable for all age groups. Our objective is to grow steadily while maintaining profitability, reinvesting our earnings to broaden our range of brands and licenses, ultimately establishing ourselves as a global IP entity. With a track record of successful product marketing, we are committed to delivering profitable and resonant content to our audiences, while simultaneously expanding our brand portfolio
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 pt-5">
                  <div className="info info-horizontal info-custom text-right pl-5">

                    <div className="description">
                      <span className="icon icon-primary">
                        <i className="tim-icons icon-coins" />
                      </span>
                      <h3 className="info-title text-left text-primary">Funding initiative and revenue sharing</h3>
                      <p className="text-dark">
                        Token holders can engage in product funding initiatives related to licensed brand opportunities, providing them with a stake in potential ventures.
                      </p>
                    </div>
                  </div>
                </div>


                {/* <Col lg="6" xs="10">
               
                <img alt="..." className="w-100" id="imageShape1" style={{ overflow: "visible" }} transform="matrix(0.9488 0 0 0.9488 25 53.1187)" width="900" src={require("assets/img/peels-business2.png")} />   
                
              </Col> */}
              </div>
            </div>
          </Container>


          {/* for small screen */}

          <Container className="d-sm-block d-md-none">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title mb-4 text-primary">THE BUSINESS OF PEELS</h2>
                <div className="section-space" />
              </Col>
            </Row>

            <div className="align-items-center" style={{ background: `no-repeat center / 50% url('${process.env.PUBLIC_URL}/assets/img/peels-business2.png')`, backgroundSize: "contain", zIndex: '1', }} >
              <div className="row" style={{ paddingBottom: "180px" }}>
                <div className="col-12 text-middle">
                  <div className="info info-horizontal info-custom text-center">
                    <div className="description">
                      <span className="icon icon-warning">
                        <i className="tim-icons icon-atom" />
                      </span>
                      <h3 className="info-title text-primary">Bringing web 2 brands to web 3</h3>
                      <p className="text-dark">
                        PEELS collaborates & partners with global brands to launch products and projects via the PEELS marketplace. This streamlined approach bypasses legal complexities and enables Peer to Peer (P2P) sales. Furthermore, the marketplace offers advertising opportunities for brands and project spotlights.

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12" style={{ paddingBottom: "200px" }}>
                  <div className="info info-horizontal info-custom text-center">
                    <div className="description">
                      <span className="icon icon-info">
                        <i className="tim-icons icon-user-run" />
                      </span>
                      <h3 className="info-title text-primary">Service providers for web 2/3 companies while Utilising PEELS</h3>
                      <p className="text-dark">
                        PEELS encourages the use of its tokens and services for design, toy and merchandise production, licensing, distribution, and marketing. This initiative also supports emerging artists seeking entry into the Web 3 landscape. We also aim to be a dominant service provider for projects in web 3 to use our services for design and toy production and distribution, which will bring in significant revenues for the company as more and more projects migrate to incorporating phygitals and collectibles. Regardless of the type of activity, PEELS will be utilized every step of the way.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-12">
                  <div className="info info-horizontal info-custom text-center">
                    <div className="description">
                      <span className="icon icon-info">
                        <i className="tim-icons icon-money-coins" />
                      </span>
                      <h3 className="info-title text-left text-primary">Reinvesting profits to expand license & brand portfolios Scaling</h3>
                      <p className="text-dark">
                        Utilizing our licenses and expanding network, we target diverse IPs across all collectible sectors, emphasizing universally appealing IPs suitable for all age groups. Our objective is to grow steadily while maintaining profitability, reinvesting our earnings to broaden our range of brands and licenses, ultimately establishing ourselves as a global IP entity. With a track record of successful product marketing, we are committed to delivering profitable and resonant content to our audiences, while simultaneously expanding our brand portfolio
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="info info-horizontal info-custom text-center">

                    <div className="description">
                      <span className="icon icon-primary">
                        <i className="tim-icons icon-coins" />
                      </span>
                      <h3 className="info-title text-left text-primary">Funding initiative and revenue sharing</h3>
                      <p className="text-dark">
                        Token holders can engage in product funding initiatives related to licensed brand opportunities, providing them with a stake in potential ventures.
                      </p>
                    </div>
                  </div>
                </div>


                {/* <Col lg="6" xs="10">
               
                <img alt="..." className="w-100" id="imageShape1" style={{ overflow: "visible" }} transform="matrix(0.9488 0 0 0.9488 25 53.1187)" width="900" src={require("assets/img/peels-business2.png")} />   
                
              </Col> */}
              </div>
            </div>
          </Container>
        </div>
        {/* ********* END FEATURES 6 ********* */}



        {/* ********* FEATURES 6 duplicate ********* */}
        {/* <div className="features-6">
          <Container>
            <Row className="align-items-center" style={{ backgroundImage: `${process.env.PUBLIC_URL}/static/media/peels-business.png` }}>       
              <Col lg="4">
                <div className="info info-horizontal info-default">
                  <div className="icon icon-warning">
                    <i className="tim-icons icon-atom" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">Bringing web 2 brands to web 3</h3>
                    <p>
                    PEELS collaborates & partners with global brands to launch products and projects via the PEELS marketplace. This streamlined approach bypasses legal complexities and enables Peer to Peer (P2P) sales. Furthermore, the marketplace offers advertising opportunities for brands and project spotlights.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal info-default">
                  <div className="icon icon-info">
                    <i className="tim-icons icon-app" />
                  </div>
                  <div className="description">
                    <h3 className="info-title text-left">Service providers for web 2/3 companies while Utilising PEELS</h3>
                    <p>
                    PEELS encourages the use of its tokens and services for design, toy and merchandise production, licensing, distribution, and marketing. This initiative also supports emerging artists seeking entry into the Web 3 landscape. We also aim to be a dominant service provider for projects in web 3 to use our services for design and toy production and distribution, which will bring in significant revenues for the company as more and more projects migrate to incorporating phygitals and collectibles. Regardless of the type of activity, PEELS will be utilized every step of the way.
                    </p>
                  </div>
                </div>

              </Col>
              <Col lg="4" xs="10">
                <img
                  alt="..."
                  className="shape"
                  src={require("assets/img/path2.png")}
                />
                <figure className="ie-non-standard-hero-shape">
                  <svg
                    className="injected-svg js-svg-injector"
                    style={{ enableBackground: "new 10 12 878.9 907" }}
                    viewBox="10 12 878.9 907"
                    x="0px"
                    y="0px"
                    xmlSpace="preserve"
                  >
                    <g>
                      <defs>
                        <path
                          d="M329.15,403.44c30.22-62,26.51-223.94,94.06-268.46C479,98.23,560.16,257,700.68,151.61c71.25-53.44,85.54,81,160.38,172.6C1008.5,504.74,881.5,639.14,825.35,686.6c-62.54,52.85-246.14,24.42-386.7,79.28S214.07,834,202.07,702C190.39,573.57,288.69,486.43,329.15,403.44Z"
                          id="firstShape"
                        />
                      </defs>
                      <clipPath id="secondShape">
                        <use
                          style={{ overflow: "visible" }}
                          xlinkHref="#firstShape"
                        />
                      </clipPath>
                      <g clipPath="url(#secondShape)">
                        <image
                          height="900"
                          id="imageShape1"
                          style={{ overflow: "visible" }}
                          transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                          width="900"
                          xlinkHref={require("assets/img/peels-business.png")}
                        />
                      </g>
                    </g>
                    <g>
                      <defs>
                        <path
                          d="M337.17,855.62c-7.81-35.46,42.38-43.95,63.66-52.44,24.39-9.74,135.86-48,192.58-52.52,64.22-5.13,190.21-26.84,160.46,35.34-19.76,41.3-51.47,64.52-87.63,62.33-46.36-2.81-101.56,4.39-150.8,44C448.53,946.08,450.93,865,412,868,372.28,871,340.79,872.08,337.17,855.62Z"
                          id="thirdShape"
                        />
                      </defs>
                      <clipPath id="fourthShape">
                        <use
                          style={{ overflow: "visible" }}
                          xlinkHref="#thirdShape"
                        />
                      </clipPath>
                      <g
                        clipPath="url(#fourthShape)"
                        transform="matrix(1 0 0 1 0 0)"
                      >
                        <image
                          height="1000"
                          id="imageShape2"
                          style={{ overflow: "visible" }}
                          transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                          width="900"
                          xlinkHref={require("assets/img/peels-business.png")}
                        />
                      </g>
                    </g>
                  </svg>
                </figure>
              </Col>
              <Col lg="4">

                <div className="info info-horizontal info-default">
                  <div className="icon icon-success">
                    <i className="tim-icons icon-bell-55" />
                  </div>
                  <div className="description">
                    <h3 className="info-title text-left">Funding initiative and revenue sharing</h3>
                    <p>
                    Token holders can engage in product funding initiatives related to licensed brand opportunities, providing them with a stake in potential ventures.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal info-default">
                  <div className="icon icon-success">
                    <i className="tim-icons icon-bell-55" />
                  </div>
                  <div className="description">
                    <h3 className="info-title text-left">Reinvesting profits to expand license & brand portfolios Scaling</h3>
                    <p>
                    Utilizing our licenses and expanding network, we target diverse IPs across all collectible sectors, emphasizing universally appealing IPs suitable for all age groups. Our objective is to grow steadily while maintaining profitability, reinvesting our earnings to broaden our range of brands and licenses, ultimately establishing ourselves as a global IP entity. With a track record of successful product marketing, we are committed to delivering profitable and resonant content to our audiences, while simultaneously expanding our brand portfolio
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        {/* ********* END FEATURES 6 duplicate ********* */}

        {/* ********* TEAM 4 ********* */}
        <div className="team-4" id="tokenomics">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title text-primary">TOKENOMICS & VESTING SCHEDULE </h2>
                <h4 className="description">
                  PEELS has a maximum supply of 444,000,000 with no option to ever
                  increase this amount.

                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" lg="10">
                <Card className="card-profile card-horizontal">
                  <Row>
                    <Col xl="7">
                      <div className="card-image no-mask">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/tokenomics.png")}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col xl="5">
                      <CardBody className="mt-3">
                        <Row>
                          <Col lg="6">
                            <h6 className="card-category">Token Name</h6>
                            <CardTitle tag="h4">PEELS</CardTitle>
                          </Col>

                          <Col lg="6">
                            <h6 className="card-category">Token Symbo</h6>
                            <CardTitle tag="h4">PEELS</CardTitle>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <h6 className="card-category">Network</h6>
                            <CardTitle tag="h4">Ethereum</CardTitle>
                          </Col>

                          <Col lg="6">
                            <h6 className="card-category">Contract Address</h6>
                            <CardTitle tag="h4"></CardTitle>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <h6 className="card-category">Contract Audit</h6>
                            <CardTitle tag="h4">Certik</CardTitle>
                          </Col>
                          <Col lg="6">
                            <h6 className="card-category">Total Fixed Supply</h6>
                            <CardTitle tag="h4">444,000,000</CardTitle>
                          </Col>
                        </Row>
                      </CardBody>

                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END TEAM 4 ********* */}

        <div className="cd-section" id="tables">
          {/* ********* Table 1 ********* */}
          <div className="tables-1">
            <Container>
              <Row>
                <Col className="mx-auto" md="8">
                  <div>
                    <Card className="card-plain">
                      <CardBody className="p-5">
                        <Table className="table-pricing table-dark table-striped" responsive>
                          <thead className="text-primary p-5">
                            <tr>
                              <th>
                                <h3 className="mb-0 text-primary">Round</h3>
                              </th>
                              <th>
                                <h4 className="desc text-primary">Allocation  %<br />of max supply</h4>
                              </th>
                              <th>

                                <h4 className="desc text-primary">Allocation <br /> (Token)</h4>

                              </th>
                              <th>
                                <h4 className="desc text-primary">Cliff Months</h4>
                              </th>
                              <th>
                                <h4 className="desc text-primary">Vesting<br />(Months)</h4>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Team & Advisors
                              </td>
                              <td>
                                5%
                              </td>
                              <td>
                                22,200,000
                              </td>
                              <td>

                              </td>
                              <td>
                                TBC
                              </td>
                            </tr>
                            <tr>
                              <td>Ecosystem</td>
                              <td>
                                30%
                              </td>
                              <td>
                                133,200,000
                              </td>
                              <td>

                              </td>
                              <td>
                                TBC
                              </td>
                            </tr>
                            <tr>
                              <td>Staking</td>
                              <td>
                                20%
                              </td>
                              <td>
                                88,800,000
                              </td>
                              <td>

                              </td>
                              <td>
                                TBC
                              </td>
                            </tr>
                            <tr>
                              <td>Development</td>
                              <td>
                                20%
                              </td>
                              <td>88,800,000</td>
                              <td>

                              </td>
                              <td>
                                TBC
                              </td>
                            </tr>
                            <tr>
                              <td>Airdrop</td>
                              <td>
                                3.235%
                              </td>
                              <td>
                                14,363,400
                              </td>
                              <td>

                              </td>
                              <td>
                                TBC
                              </td>
                            </tr>
                            <tr>
                              <td>Liquidity Management</td>
                              <td>
                                2%
                              </td>
                              <td>
                                8,880,000
                              </td>
                              <td>

                              </td>
                              <td>
                                TBC
                              </td>
                            </tr>
                            <tr>
                              <td>Round Raises</td>
                              <td>
                                19.765%
                              </td>
                              <td>
                                87,756,600
                              </td>
                              <td></td>
                              <td>
                                TBC
                              </td>
                            </tr>
                            <tr className="bg-primary">
                              <td>Total</td>
                              <td>
                                100%
                              </td>
                              <td>
                                444,000,600
                              </td>
                              <td></td>
                              <td>
                                TBC
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col md="4" className="mt-5">
                  <h2 className="mt-5 text-primary">Please note:</h2>
                  <h4 className="description">These figures will be adjusted overtime and do not reflect the final allocation of tokens. </h4>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END Table 1 ********* */}
        </div>

        {/* ********* FEATURES 5 ********* */}
        <div className="features features-5" id="marketgrowth">
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <div className="featured-image">
                  <img alt="..." height="600" src={require("assets/img/market-chart.png")} width="100%" />
                </div>
              </Col>
              <Col className="mt-md-5" lg="6">
                {/* <h6 className="category">Read this first</h6> */}
                <h1 className="title text-primary">Market Growth & Expansion
                </h1>
                <Row>
                  <Col lg="12" md="12">
                    <div className="">
                      {/* <h4 className="info-title">Honest</h4> */}
                      <p className="description">
                        The toy industry continues to grow YoY with 2023 bringing the industry over 160 Billion USD.<br /><br /> We plan to capitalize on the earliness of our venture into the toy industry via our marketplace by already having heavy hitting partnerships in place such as Mattel, Warner Brothers, Disney and more.<br /><br /> We believe we can capture a significant portion of the market share by targeting Web 2 consumers and transferring them over to Web 3, a Primary focus on elevating targeted markets such as USA, China & Japan to spearhead our growth to cement our place within the industry.
                      </p>
                    </div>
                  </Col>

                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 5 ********* */}



        {/* 
          <Container>
        <div className="title text-center">
          <h3>Our Partners</h3>
        </div>       
        </Container>
        <div className="social-line social-line-big-icons">
          <Container>
            <Row>
              <Col md="2">
                <Button
                  className="btn-icon btn-simple btn-footer"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
               
                  <img alt="..." src={require("assets/img/disney-logo.png")} height={'100px'}  />
                </Button>
              </Col>
              <Col md="2">
                <Button
                  className="btn-icon btn-simple btn-footer"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  
                  <img
                    alt="..."
                    src={require("assets/img/partners/samsung-logo2.png")}  height={'100px'} 
                  />
                </Button>
              </Col>
              <Col md="2">
                <Button
                  className="btn-icon btn-simple btn-footer"
                  color="google"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                 
                  <img
                    alt="..."
                    src={require("assets/img/kfc-logo.png")}  height={'100px'} 
                  />
                </Button>
              </Col>
              <Col md="2">
                <Button
                  className="btn-icon btn-simple btn-footer"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                 
                  <img
                    alt="..."
                    src={require("assets/img/partners/wwfWhite-logo.png")} height={'100px'} 
                  />
                </Button>
              </Col>
              <Col md="2">
                <Button
                  className="btn-icon btn-simple btn-footer"
                  color="youtube"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                 
                  <img
                    alt="..."
                    src={require("assets/img/partners/puma-logo.png")} height={'100px'} 
                  />
                </Button>
              </Col>
              <Col md="2">
                <Button
                  className="btn-icon btn-simple btn-instagram btn-footer"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                 
                  <img
                    alt="..."
                    src={require("assets/img/partners/oppo-logo.png")} height={'100px'} 
                  />
                </Button>
              </Col>
            </Row>
          </Container>
        </div> */}


        <SlickCarousel />

        {/* <OurPartners />     */}


        {/*****************  feature 1 *********************/}
        <div className="features-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">

                <h1 className="title text-primary">Timeline guide for where we're at currently</h1>
              </Col>
            </Row>
            <Row>

              <Col md="6" className="ml-auto mr-auto">
                <div className="card bg-dark h-100">
                  <div className="card-header pb-0">
                    {/* <h3>Timeline guide for where we're at currently</h3> */}
                    {/* <p className="text-sm">                      
                      <span className="font-weight-bold">24%</span> 
                   
                    </p> */}
                  </div>
                  <div className="card-body p-3 pl-5">
                    <div className="timeline timeline-one-side">
                      <div className="timeline-block mb-5">
                        <span className="timeline-step">
                          <i className="tim-icons icon-spaceship text-success text-gradient " style={{ fontSize: '2rem' }}></i>
                        </span>
                        <div className="timeline-content text-left ml-5">
                          <p className="text-secondary font-weight-bold mt-1 mb-3">2024</p>

                          {/* <h6 className=" font-weight-bold mb-0 text-center pt-3">2024</h6> */}
                          <p className="mb-3"><span class="ml-1 mt-2 badge badge-primary">November</span><br />
                            <span className="text-secondary font-weight-bold text-xs mt-1 mb-0 ml-2">*Ron English toy Q2/Q3</span>
                          </p>
                          <p><span class="ml-1 badge badge-primary">December</span><br />
                            <span className="text-secondary font-weight-bold text-xs mt-1 mb-0 ml-2">*Angry birds toy & Merch collection</span><br />
                            <span className="text-secondary font-weight-bold text-xs mt-1 mb-0 ml-2">&nbsp;&nbsp;SEGA</span><br />
                            <span className="text-secondary font-weight-bold text-xs mt-1 mb-0 ml-2">&nbsp;&nbsp;ROVIO</span><br />

                          </p>
                        </div>
                      </div>
                      <div className="timeline-block mb-5">
                        <span className="timeline-step">
                          <i className="tim-icons icon-spaceship text-info text-gradient" style={{ fontSize: '2rem' }}></i>
                        </span>
                        <div className="timeline-content text-left ml-5">
                          <p className="text-secondary font-weight-bold mt-1 mb-3">2025</p>

                          {/* <p className="mb-3"><span class="ml-1 mt-2 badge badge-primary">November</span><br/> */}
                          <span className="text-secondary font-weight-bold text-xs mt-1 mb-0 ml-2">*Mattel Partnership with products</span>

                        </div>
                      </div>
                      <div className="timeline-block mb-2">
                        <span className="timeline-step">
                          <i className="tim-icons icon-spaceship text-danger text-gradient" style={{ fontSize: '2rem' }}></i>
                        </span>
                        <div className="timeline-block mb-5">
                          <span className="timeline-step">
                            <i className="tim-icons icon-spaceship text-danger text-gradient" style={{ fontSize: '2rem' }}></i>
                          </span>
                          <div className="timeline-content text-left ml-5">
                            <p className="text-secondary font-weight-bold mt-1 mb-3">2026</p>

                            {/* <p className="mb-3"><span class="ml-1 mt-2 badge badge-primary">November</span><br/> */}
                            <span className="text-secondary font-weight-bold text-xs mt-1 mb-0 ml-2">*Bearbrick Partnership with products & global launches</span>

                          </div>
                        </div>
                      </div>
                      <div className="timeline-block mb-2">
                        <span className="timeline-step">
                          <i className="tim-icons icon-spaceship text-warning text-gradient" style={{ fontSize: '2rem' }}></i>
                        </span>                    
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

            </Row>
          </Container>
        </div>

        {/* ********* FEATURES 2 ********* */}
        <div className="features-2">
          <Container>
            <Row className="align-items-center">
              <Col className="mr-auto text-left" lg="2" md="8">
                <h1 className="title text-primary">HOLDER BENEFITS</h1>
                {/* <p className="description">
                  5,000 capacity venue, holding some of the latest technology
                  lighting with a 24 colour laser system Amnesia is one of the
                  islands most legendary clubs.
                </p>
                <Button
                  className="btn-simple"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Learn more <i className="tim-icons icon-alert-circle-exc" />
                </Button> */}
              </Col>
              <Col lg="9" md="12">
                <Row>
                  <Col lg="4" md="4">
                    <div className="info text-left bg-info">
                      <div className="icon icon-white">
                        <i className="tim-icons icon-coins" />
                      </div>
                      <h4 className="info-title">Revenue Sharing</h4>
                      <p className="description">
                        Presale investors will participate in a revenue-sharing program, entitling them to a proportional share of the platform's generated fees, contingent upon their token holdings.
                      </p>
                    </div>
                  </Col>
                  <Col lg="4" md="4">
                    <div className="info text-left bg-danger info-raised">
                      <div className="icon icon-white">
                        <i className="tim-icons icon-tag" />
                      </div>
                      <h4 className="info-title">Discounted Tokens</h4>
                      <p className="description">
                        Avail yourself of the exclusive opportunity to acquire PEELS tokens at a discounted rate during the presale phase, positioning early investors to capitalize on the platform's anticipated growth. The presale comprises several stages, each featuring incremental price increases as token availability diminishes.
                      </p>
                    </div>
                  </Col>
                  <Col lg="4" md="4">
                    <div className="info text-left bg-default">
                      <div className="icon icon-white">
                        <i className="tim-icons icon-vector" />
                      </div>
                      <h4 className="info-title">Early Access</h4>
                      <p className="description">
                        Gain privileged exclusive early access to the PEELS exclusive drops with licensed brands and licensed projects. The Exclusive drops will be limited in quantity thereby creating FOMO for others who do not have access to the early access if it is sold out.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="4">
                    <div className="info text-left bg-primary info-raised">
                      <div className="icon icon-white">
                        <i className="tim-icons icon-gift-2" />
                      </div>
                      <h4 className="info-title">Discounted Products</h4>
                      <p className="description">
                        Enjoy discounted pricing on select products featured on the PEELS platform, enhancing your purchasing power and value proposition.

                      </p>
                    </div>
                  </Col>
                  <Col lg="4" md="4">
                    <div className="info text-left bg-success">
                      <div className="icon icon-default">
                        <i className="tim-icons icon-bulb-63" />
                      </div>
                      <h4 className="info-title text-muted">
                        Insider Updates
                      </h4>
                      <p className="description text-muted">
                        Gain access to privileged insights and updates about the ongoing development of the PEELS marketplace & upcoming products, furnishing investors and enthusiasts alike with invaluable scheduled information regarding products and services.
                      </p>
                    </div>
                  </Col>
                  <Col lg="4" md="4">
                    <div className="info text-left bg-warning info-raised">
                      <div className="icon icon-white">
                        <i className="tim-icons icon-settings" />
                      </div>
                      <h4 className="info-title">Priority Support -</h4>
                      <p className="description">
                        Benefit from priority support services provided by the PEELS team, ensuring expedited resolution of inquiries and concerns.

                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 2 ********* */}

        <div className="cards mt-4">
          {/* ********* PROFILE CARDS ********* */}
          <div className="cards mb-0">
            <Container>
              <div class="row"><div class="ml-auto mr-auto text-center col-md-9"><h2 class="title text-primary">THE TEAM</h2>
                <h4 class="description">Meet the dynamic duo behind 4 STAGES, Apes R Us NFT project & PEELS - David and Katun, visionary
                  leaders seamlessly blending diverse backgrounds to create a global impact. Together, David and Katun define the unparalleled journey of 4 STAGES - a fusion of business brilliance, cultural resonance, and groundbreaking artistic vision. Witness the global impact as their collaborations continue to resonate and captivate audiences worldwide.</h4></div></div>
              <Row>
                <Col className="ml-auto mr-auto" lg="6" md="6">
                  <Card className="card-profile bg-dark">
                    <div className="card-image">
                      {/* <h4 className="title">Dylan Wyatt</h4> */}
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/Team-member-1.png")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <CardTitle tag="h3">David Ku</CardTitle>
                      <h6 className="category text-primary">CEO & Co-founder</h6>
                      <p className="card-description">
                        Originating from the UK, David's cross-cultural journey between Asia and Europe having lived in Hong Kong, Shanghai & now Kuala Lumpur-fuels
                        his profound insights into both business and culture. With a corporate finance background in commodities, collaborating with global entities like Glencore, Trafigura & Noble Group. His passion for art lead him to identify a void in brand agencies and therefore founded 4 STAGES. His strategic vision has propelled the company to global prominence, fostering partnerships with renowned brands while also co creating APES R US (ARU). Currently, David focuses on bridging the gap between Web 2 and Web 3 technologies through his new IP Phygital Brand "EPZ" while advocating for street artists through the 4 STAGES Brand.
                      </p>
                    </CardBody>
                    <CardFooter>
                      <div className="follow float-left">

                      </div>
                      <div className="d-inline float-right">
                        <Button
                          className="btn-icon btn-round"
                          color="twitter"
                          href="https://x.com/hkdavidku"
                          // onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon btn-round ml-1"
                          color="linkedin"
                          href="https://www.linkedin.com/in/david-ku-088b7850/"
                          // onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-linkedin" />
                        </Button>
                        {/* <Button
                          className="btn-icon btn-round ml-1"
                          color="behance"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-behance" />
                        </Button> */}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="6" md="6">
                  <Card className="card-profile bg-dark">
                    <div className="card-image">
                      {/* <h4 className="title">Dylan Wyatt</h4> */}
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/Team-member-2.png")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <CardTitle tag="h3">Katun</CardTitle>
                      <h6 className="category text-primary">Renowned Street Artist & Co-founder</h6>
                      <p className="card-description">
                        Hailing from the heart of Malaysia, Katun is an award winning international artist renowned for his distinctive graffiti style, seamlessly
                        blending darkness and light. Co-founder of the avant-garde art collective 4 STAGES, Katun has left an indelible mark through impactful collaborations with prominent brands like Sony, JBL, and KFC. His artistic prowess has garnered attention from Grammy winners Chris Brown, Dua Lipa, and Post Malone. Katun's global influence is reflected in prestigious awards like the Tatler Magazine Cultural Icon Award (2022) and the Prestige Magazine Luminary Award (2023). His ongoing
                        collaboration with Sony Entertainment on the blockbuster movie "Spiderman: Into the Spiderverse" solidifies his exceptional contributions to the artistic realm. Keep your eyes “Peeled” for more of his work coming to cities near you!
                      </p>
                    </CardBody>
                    <CardFooter>
                      <div className="follow float-left">

                      </div>
                      <div className="d-inline float-right">
                        <Button className="btn-icon btn-round" color="twitter" href="https://x.com/KatunVisual">
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button className="btn-icon btn-round ml-1" color="google" href="https://www.instagram.com/katun_">
                          <i className="fab fa-instagram" />
                        </Button>

                        {/* <Button className="btn-icon btn-round ml-1" color="behance" href="#pablo" onClick={(e) => e.preventDefault()} >
                          <i className="fab fa-behance" />
                        </Button> */}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END PROFILE CARDS ********* */}
        </div>


        <ContactUs />

        <DemoFooter />

        {/* <IndexHeader />
        <div className="main">
          <Basic />
          <Navbars />
          <Tabs />
          <Pills />
          <Pagination />
          <Notifications />
          <PreFooter /> 
          <Footers />
          <Typography />
          <ContentAreas />
          <Cards />
          <PlainCards />
          <JavaScript />
          <NucleoIcons />
        </div>
        <DemoFooter /> */}
      </div>
    </>

  );
}
